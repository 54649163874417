import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import Cta from "../../components/Cta";
import ContentImg from "../../components/GSTTemp/ContentImg";
import SliderContent from "../../components/GSTTemp/SliderContent";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function GstAnnulReturnFiling() {
  /* Slider */
  // const retSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Gst Annual Return Filing`,
  //     content: `India's Best GST Registration Service`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag: "Best Online Gst Annual Return Filing Service Near Bangalore",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Gst Annual Return Filing`,
  //     content: `Best GST Registration Service in Hosur`,
  //     image: "/imgs/registration/msme/msme-slide.png",
  //     alt_tag: "Best Online Gst Annual Return Filing Service in Hosur",
  //   },
  //   {
  //     header_color: false,
  //     content_color: false,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online Gst Annual Return Filing Service`,
  //     content: `Best Gst Annual Return Filing Service Platform`,
  //     image: "/imgs/registration/msme/ms-me-reg.png",
  //     alt_tag: "Best Online Gst Annual Return Filing Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `GST Annual Return Filing`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const retAboutData = {
    header: `(GST) Annual Return Filing`,
    sub_title: `Annual GST Return Filing With the TODAYFILINGS Experts.`,
    content_paragraph: [
      `All organizations with a revenue 
      of more than two crores must file an annual GST return in order to be registered 
      for GST. In accordance with the Goods and Services Tax's laws and regulations, even
       an inactive entity that registers for GST is required to submit GST returns (GST).`,

      //   `A registered taxpayer who wants to file the GSTR 9 must do so once every year. All supplies made and received for the year
      //   will be listed in this document along with turnover and audit data under the several tax titles (CGST, SGST, and IGST).`,
      //   `The GSTR 9C audit form, which must be submitted annually by taxpayers with a turnover of more than Rs 2 crore, was introduced
      // by the government. `,
    ],
  };

  /* Img Content Component Data */
  const retIcData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'When Does GSTR9 Have To Be Filed?',
    image: '/imgs/registration/tax/tax-img-sliders.png',
    alt_tag: 'MsMe Registration Procedure',
    Paragraph: `According to the 37th GST Council Meeting, GSTR-9 for the fiscal year 2018-2019 must be 
    submitted by November 30, 2019. The return must be sent in by December 31 of the following year. 
    (If you finish the GSTR-9 for 2019, for instance, you have until December 31, 2020.)`,
    points: [
      {
        head: `Types of GSTR 9:`,
        content: `There are four main types of annual returns allowed by the GST Act, and they are as follows:`,
        icon: true,
      },
      {
        content: `Regularly enrolled taxpayers must submit GSTR 9 along with GSTR 1 and GSTR 3B.`,
        icon: true,
      },
      {
        content: `Registered composite dealers are required to submit GSTR 9A. (Composition Scheme).`,
        icon: true,
      },
      {
        content: `Businesses engaged in e-commerce that collect tax at source and file GSTR 8 for the fiscal year are required to file GSTR 9B.`,
        icon: true,
      },
      {
        content: `All taxpayers who have an annual revenue of more than Rs 2 crores and who are required to have their annual reports audited must file GSTR 9C, which is an audit form.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const retCfData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'GSTR-9 Annual Return Filing Instructions',
    content: ``,
    body: [
      {
        head: 'Part 1: ',
        points: `The first section of the GSTR 9 format 
          requests essential information such as your fiscal year, GSTIN, legal name, and trade name.`,
        icon: true,
      },
      {
        head: 'Part 2: Details On Stated Outbound And Inbound Supplies For The Fiscal Year',
        points: `The GSTR9 format's second portion is divided into two sections to collect data on various types of transactions.`,
        icon: true,
      },
      {
        head: 'In Section 4',
        points: ``,
        icon: false,
      },
      {
        points: `Provisions made available to those who are not registered (B2C).`,
        icon: true,
      },
      {
        points: `Provisions made available to registered users (B2B).`,
        icon: true,
      },
      {
        points: `supplies with a tax rate of zero that have already been shipped (except supplies made to SEZs).`,
        icon: true,
      },
      {
        points: `supplying Special Economic Zones tax-free.`,
        icon: true,
      },
      {
        points: `imported goods that are considered exports.`,
        icon: true,
      },
      {
        head: 'Section 5',
        points: ``,
        icon: false,
      },

      {
        points: `supplier with a zero rating that was tax-free when exported.`,
        icon: true,
      },
      {
        points: `supplies to special economic zones that are tax-free.`,
        icon: true,
      },
      {
        points: `Supplies that require the recipient to pay the reverse charge tax.`,
        icon: true,
      },
      {
        points: `Sales supplies are not included.`,
        icon: true,
      },
      {
        points: `Sales of unrated supplies.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const retCiData = {
    id: '',
    background: 'main-content',
    mt_div: 'mt-3',
    header: 'Annual Return Instructions',
    paragraph: ``,
    points: [
      {
        head: 'Part 3: ITC details as reported in tax returns submitted throughout the fiscal year',
        content: ` `,
        icon: false,
      },
      {
        head: 'Section 6 ',
        content: ``,
        icon: false,
      },
      {
        content: `Any ITC you have claimed must be fully disclosed in the returns you submitted during 
        the financial year. The following values should be entered for federal, state, integrated taxes, and cess.`,
        icon: true,
      },
      {
        head: 'Section 7',
        content: ``,
        icon: false,
      },
      {
        content: `For the reversed ITC and ineligible ITC on the central and state taxes, integrated tax, 
        and cess value, you must enter the following information.`,
        icon: true,
      },
      {
        head: 'Section 8',
        content: ``,
        icon: false,
      },
      {
        content: `Fill in the following values for central and state taxes, integrated taxes, and cess:`,
        icon: true,
      },
    ],
    image: '/imgs/registration/tax/tax-reg.png',
    alt_tag: 'Required Documents for MsMe Registration',
  };

  /* Image Slider */
  const imgSlider = [
    '/imgs/business/form-filing.png',
    '/imgs/business/responsible.png',
    '/imgs/business/duties.png',
  ];

  /*  Slider Content Component Data */
  const retSCData = {
    id: '',
    background: [],
    mt_div: 'mt-3',
    header: 'Submit tax return',
    content: [
      {
        head: 'Part 4: Information On Taxes Paid As Reported In Returns Filed During The Fiscal Year',
        points: ``,
        icon: false,
      },
      {
        points: `You can enter all the information on the taxes you paid and declared in returns that 
        were submitted throughout the financial year in this area of the form.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /* Img Content Component Data */
  const returnIcData = {
    id: '',
    mt_div: 'mt-5',
    mt_img: '',
    background: '',
    header: 'Specific Transactions',
    image: '/imgs/registration/iec/iec.png',
    alt_tag: 'MsMe Registration Procedure',
    Paragraph: ``,
    points: [
      {
        head: `Part 5: The specifics of transactions from the prior fiscal year that were disclosed in 
        returns from April through September of the current fiscal year, or up until the annual return's 
        filing date from the previous fiscal year, whichever came first.`,
        content: ``,
        icon: false,
      },
      {
        head: 'Section 10',
        content: ``,
        icon: false,
      },
      {
        content: `The place where you can list all specifics about transactions from the previous fiscal year. Fill in the taxable value, federal and state taxes, integrated tax, and cess value for the following:`,
        icon: true,
      },
      {
        content: `Goods or taxes that have been declared as a result of modifications.`,
        icon: true,
      },
      {
        content: `Due to changes, the price of the supplies or the tax is lower.`,
        icon: true,
      },
      {
        content: `Cancellation of the ITC from the previous fiscal year.`,
        icon: true,
      },
      {
        content: `ITC obtained during the previous fiscal year.`,
        icon: true,
      },
    ],
  };

  /* FaQ data */
  var GSTAnnualFAQ = [
    {
      header: `What is an annual return?`,
      body: [
        {
          content: `Annual Return is a statement of return that is required to be filed annually by
          each registered person (except a few specified categories of persons) under GST
          giving summarized details of outward supply and taxes paid thereon, input
          tax credits claimed, taxes paid, and refund claimed in the financial year in
          respect of which such annual return is filed. It also includes the transactions
          pertaining to the said financial year in respect of which the effect has been
          taken in the next financial year as well.`,
          icon: false,
        },
      ],
    },
    {
      header: `What should be done if a sales figure originally reported as B2C
    sales in GSTR-1 was later adjusted to B2B sales?`,
      body: [
        {
          content: ` Table 4 of the GSTR-9 must include vendors who are both B2B and B2C.
        Even though the sales were misrepresented as B2C in GSTR-1, the
        assessee must categorize them as B2B in Table 4 because this is the`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: `Should sales previously recorded as "Without Payment of Taxes" now
    be classified as "Exports with Payment of Taxes" if a LUT was not
    applied?`,
      body: [
        {
          content: `Failure to execute the LUT is a procedural non-compliance. Due to
        non-compliance, the transaction's type cannot be changed from
        "exports without payment of tax" to "exports with payment of tax."
        The transaction needs to be reported as "Exports without tax
        payment" in Table 5 of GSTR-9. Penalties must be addressed if any
        exist.`,
          icon: false,
        },
      ],
    },
    {
      header: `How should an advance be handled in Table 4F if it was received in
    2017-18, the invoice was issued in 2017-18, but was reported late in
    GSTR 1 in FY(2018-19)?`,
      body: [
        {
          content: `If the transaction involves a supply to an unregistered person, it
        should be recorded in Table 4A; if it involves a supply to a
        registered person, it should be reported in Table 4B. (in case the
        supply is made to a registered person). only when Table 4F reporting
        is used and the tax on advances has been paid but no invoice has
        been issued.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: `Is it possible to declare a new credit in GSTR-9 that was not
      claimed in the GSTR-3B filings?`,
      body: [
        {
          content: `No further credit that was not claimed in GSTR-3B may be claimed in
        GSTR-9. On the other hand, credit claimed in a TRAN-1 filing may be
        reported in GSTR-9 under Table 6K/6L or Table 13, as applicable.`,
          icon: false,
        },
      ],
    },
    // 4
    {
      header: `How do we divide the ITC into three categories: capital goods,
    inputs, and services?`,
      body: [
        {
          content: `Table 6B of GSTR- requires the split of ITC into Capital Goods,
        Inputs, and Input Services. Purchases resulting from the output
        supply of goods and services, capital goods, and services must be
        noted in the books of accounts, and ITC on these purchases must be
        differentiated. On the other hand, ITC is handled the same way for
        both goods and services.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Whether Annual Return is required to be filed separately for each GSTIN?',
      body: [
        {
          content: `If a person is having multiple GSTINs, then each such GSTIN would be
          considered a distinct person. Every registered person (other than those
          mentioned in 44(1)) is required to file an annual return. Thus, the annual
          return has to be filed separately for every registration obtained by the
          registered person`,
          icon: false,
        },
      ],
    },
    {
      header:
        'Is it mandatory to file GSTR-1 and GSTR-3B before filing the Annual',
      body: [
        {
          content: `Yes, the instruction to GSTR-9 provides that it is mandatory to file GSTR-1
          and GSTR-3B before filing GSTR-9. Hence, any taxpayer who has failed to
          file GSTR-1/3B for any of the months for FY 2021-22 shall not be permitted
          to file GSTR-9.`,
          icon: false,
        },
      ],
    },
    {
      header: `If the assessee opts for a composition scheme during the financial year,
      do they need to file both GSTR-9 and GSTR-9A?
      `,
      body: [
        {
          content: `It seems that a registered taxable person needs to file an Annual Return in
          GSTR-9 for the first part of the financial year as a regular taxpayer and also
          Form GSTR-9A for the second part of the financial year as a composite
          taxpayer. However, an exemption has been provided for aggregate turnover
          up to Rs.2 crore which can be opted for. `,
          icon: false,
        },
      ],
    },
    {
      header: `Whether the annual return filed can be amended subsequently?`,
      body: [
        {
          content: `The option of amendment of Annual Return has not been provided under
          section 44 or rule 80. Therefore, once the annual return is filed, it would be
          considered final and no further amendment would be possible. Therefore,
          due care needs to be taken in the submission of information in the annual
          return. If any disclosure is missed out, an option of providing the information
          could be examined in GSTR-9C self-reconciliation statement.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Gst Annual Return Filing'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={retSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={retAboutData} />

          <Counter />
          <ImgContent item={retIcData} />

          <ContentForm CFSection_data={retCfData} />

          <SliderContent ScSection_data={retSCData} />

          <ContentImg CISection_data={retCiData} />

          <ImgContent item={returnIcData} />
          <Cta />

          <Guidance />

          <FAQAccord items={GSTAnnualFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
